import { Menu as HMenu } from "@headlessui/react"
import classNames from "classnames"
import { Fragment, PropsWithChildren, ReactNode, useState } from "react"
import { usePopper } from "react-popper"
import { Icon } from "tabler-icons-react"
import { MenuAvatar } from "./menu-avatar"

// Copied & slightly adapted from console v2

export type MenuProps = {
  gap?: number
  button: ReactNode
  align?: "start" | "end"
  openingButtonClassName?: string
}

export const Menu = ({
  children,
  button,
  gap = 28,
  align = "end",
  openingButtonClassName,
}: PropsWithChildren<MenuProps>) => {
  const [referenceElement, setReferenceElement] =
    useState<HTMLDivElement | null>(null)
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(
    null,
  )
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: `bottom-${align}`,
    modifiers: [
      {
        name: "offset",
        options: {
          offset: [0, gap],
        },
      },
    ],
  })

  return (
    <HMenu>
      <HMenu.Button
        as="div"
        ref={setReferenceElement}
        className={openingButtonClassName}
      >
        {button}
      </HMenu.Button>
      <HMenu.Items
        ref={setPopperElement}
        className="absolute z-40 min-w-[300px] overflow-hidden rounded-lg bg-white py-2 shadow-xl ring-1 ring-gray-200"
        style={styles.popper}
        {...attributes.popper}
      >
        {children}
      </HMenu.Items>
    </HMenu>
  )
}

type MenuItemProps = {
  icon: Icon
  type?: "default" | "danger" | "active"
  onClick?: () => void
  href?: string
  className?: string
  disabled?: boolean
  pill?: string
  trailing?: JSX.Element
}

const iconColor = {
  default: "text-gray-900 ui-active:text-indigo-500 ui-disabled:text-gray-300",
  danger: "text-error-500",
  active: "text-white",
}

const textColor = {
  default: "text-gray-900 ui-active:text-indigo-500 bg-transparent",
  danger: "text-gray-900 ui-active:text-error-500 bg-transparent",
  active: "bg-indigo-500 text-white ui-active:bg-indigo-700",
}

Menu.Item = ({
  icon: Icon,
  type = "default",
  onClick,
  children,
  disabled,
  href,
  pill,
  className,
  trailing,
}: PropsWithChildren<MenuItemProps>) => {
  const pillStyle =
    "text-xs text-indigo-50 bg-indigo-500 rounded px-1 py-0.5 ml-auto"

  const content = (
    <>
      <span className="flex w-full items-center gap-x-2 truncate px-2 py-2.5 text-sm">
        <Icon size={16} className={iconColor[type]} />
        {children}
        {pill && <div className={pillStyle}>{pill}</div>}
      </span>
      {trailing}
    </>
  )

  if (href) {
    return (
      <HMenu.Item as={Fragment}>
        <a
          href={href}
          className={classNames(
            "group flex cursor-pointer items-center justify-between px-4 py-2 ui-active:bg-gray-50 ui-disabled:cursor-not-allowed ui-disabled:text-gray-300",
            textColor[type],
            className,
          )}
        >
          {content}
        </a>
      </HMenu.Item>
    )
  }

  const Component = onClick ? "button" : "div"
  return (
    <HMenu.Item
      as={Component}
      disabled={disabled}
      className={classNames(
        "group flex w-full cursor-pointer items-center justify-between px-4 py-2 ui-active:bg-gray-50 ui-disabled:cursor-not-allowed ui-disabled:text-gray-300",
        textColor[type],
        className,
      )}
      onClick={onClick}
    >
      {content}
    </HMenu.Item>
  )
}

type MenuButtonItemProps = {
  onClick?: () => void
  href?: string
  className?: string
  disabled?: boolean
}

Menu.ButtonItem = ({
  onClick,
  children,
  disabled,
  href,
  className,
}: PropsWithChildren<MenuButtonItemProps>) => {
  const ButtonComponent = href ? "a" : "button"

  return (
    <HMenu.Item as={Fragment}>
      <div className="flex items-stretch gap-2 px-6 py-2 ui-active:cursor-pointer">
        <ButtonComponent
          href={href}
          disabled={disabled}
          onClick={onClick}
          className={classNames(
            "group flex w-full cursor-pointer items-center justify-center gap-2 rounded px-3 py-1 ui-disabled:cursor-not-allowed ui-disabled:text-gray-300",
            "truncate text-sm",
            "border border-transparent bg-indigo-600 text-white",
            "ui-active:border-indigo-500 ui-active:bg-white ui-active:text-indigo-500",
            className,
          )}
        >
          {children}
        </ButtonComponent>
      </div>
    </HMenu.Item>
  )
}

type MenuUserProps = {
  name: string
  email: string
  initials: string
}

Menu.User = ({ name, email, initials }: MenuUserProps) => {
  return (
    <HMenu.Item as={Fragment} disabled>
      <div className="pointer-events-auto mb-2 flex cursor-default gap-x-2 px-4 py-2 hover:bg-gray-50">
        <MenuAvatar text={initials} />
        <div className="flex flex-col">
          <p className="text-sm font-semibold text-gray-900">{name}</p>
          <p className="text-sm text-gray-400">{email}</p>
        </div>
      </div>
    </HMenu.Item>
  )
}

Menu.Divider = () => {
  return <div className="my-2 border-t border-gray-200" />
}
