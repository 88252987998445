import { Session } from "@ory/client"
import React from "react"
import { Menu } from "../menu/menu"
import { MenuAvatar } from "../menu/menu-avatar"
import { SuspenseHelper } from "../suspense"

const UserMenuItems = React.lazy(() => import("./user-menu-items"))

export type UserMenuProps = {
  session: Session
  openingButtonClassName?: string
}

export const UserMenu = ({
  session,
  openingButtonClassName,
}: UserMenuProps) => {
  const name: string = session.identity.traits.name ?? "unknown"
  const initials = name
    .split(" ")
    // Only interested in first and last initial
    .filter((_, i, all) => i === 0 || i === all.length - 1)
    .map((s) => s[0].toUpperCase())
    .join("")

  return (
    <Menu
      openingButtonClassName={openingButtonClassName}
      button={<MenuAvatar text={initials} as="button" />}
    >
      <SuspenseHelper fallback={<div></div>}>
        <UserMenuItems initials={initials} name={name} session={session} />
      </SuspenseHelper>
    </Menu>
  )
}

export default UserMenu
